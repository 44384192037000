import React from 'react';
import { Card, CardContent, CardMedia, Typography, Link } from '@mui/material';

const VenueBox = ({ venueData, highlightedSection, venueRef }) => {
    console.log(venueData);
  return (
    <Card ref={venueRef}>
      <Typography variant="h6" component="h2" sx={{ ml: 2, mt: 2 }} gutterBottom>
        Venue
      </Typography>
      <CardMedia
        component="img"
        height="200"
        image={venueData.imageUrl}
        alt={venueData.name}
      />
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          {venueData.name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {venueData.location} | Capacity: {venueData.capacity}
        </Typography>
        <Typography variant="body2" paragraph sx={{ mt: 2 }}>
          {venueData.description}
        </Typography>
        <Link 
          href={venueData.link} 
          underline="hover"
          sx={{
            transition: 'all 0.3s ease-in-out',
            ...(highlightedSection === 'venue' && {
              backgroundColor: 'rgba(25, 118, 210, 0.1)',
              padding: '4px',
              borderRadius: '4px',
            }),
          }}
        >
          Visit Venue Page
        </Link>
      </CardContent>
    </Card>
  );
};

export default VenueBox;