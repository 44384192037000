import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import QAPI from "qapi";

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';

window.QAPI = QAPI;
// ReactDOM.render(<App />, document.getElementById("root"));


ReactDOM.render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>,
    document.getElementById('root')
  );
