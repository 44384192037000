import React, { createContext, useState, useEffect, useContext } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [roles, setRoles] = useState([]); // Add roles state
  const [loading, setLoading] = useState(true);

  // Load user data from localStorage when the app starts
  useEffect(() => {
    console.log("UserProvider ------------------------");
    console.log(localStorage);
    const storedUserData = localStorage.getItem("userData");
    console.log("storedUserData");
    console.log(storedUserData);
    if (storedUserData) {
      setUser(JSON.parse(storedUserData)); // Parse and set the user data
      setRoles(JSON.parse(storedUserData.roles)); // Set roles when user is loaded
    }
  }, []);

  // useEffect(() => {
  //   const loadUser = async () => {
  //     const token = localStorage.getItem('authToken');
  //     if (token) {
  //       try {
  //         const decodedToken = jwtDecode(token);
  //         // Check if the token is expired
  //         if (decodedToken.exp * 1000 < Date.now()) {
  //           throw new Error('Token expired');
  //         }
  //         // Verify the token with the backend
  //         const response = await axios.get('http://127.0.0.1:8000/auth/verify', {
  //           headers: { Authorization: `Bearer ${token}` }
  //         });
  //         setUser(response.data.user);
  //       } catch (error) {
  //         console.error('Error loading user:', error);
  //         localStorage.removeItem('authToken');
  //       }
  //     }
  //     setLoading(false);
  //   };

  //   loadUser();
  // }, []);

  const login = async (token, userData) => {
    localStorage.setItem('authToken', token);
    setUser(userData);
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setUser(null);
  };

  const updateUserType = async (type) => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.post('http://127.0.0.1:8000/auth/update_user_type', 
        { type }, 
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.token) {
        localStorage.setItem('authToken', response.data.token);
        setUser(prevUser => ({ ...prevUser, type }));
      }
    } catch (error) {
      console.error('Error updating user type:', error);
      throw error;
    }
  };

  return (
    // <UserContext.Provider value={{ user, login, logout, updateUserType, loading }}>
    <UserContext.Provider value={{ user, roles, setUser, setRoles }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);