import React, { useState} from 'react';
import theme from "theme";
import axios from 'axios';
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import { GiFairyWings } from "react-icons/gi";
import { Grid, Typography, Box, Button, Container } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import signUpPageStyle from '../style/signupPageStyle';

const useStyles = makeStyles(theme => ({
	...signUpPageStyle(theme)
  }));
  
  const IndexPage = () => {
	const classes = useStyles();
	const [email, setEmail] = useState('');
	const [submitted, setSubmitted] = useState(false);
  
	const handleSubmit = async (e) => {
	  e.preventDefault();
	  try {
		const apiUrl = '/.netlify/functions/addToWaitlist';
		const response = await axios.post(apiUrl, { email: email });
  
		if (response.status === 200) {
		  setEmail('');
		  setSubmitted(true);
		} else {
		  console.error('Error adding email to waitlist: ', response.statusText);
		}
	  } catch (error) {
		console.error('Error adding email to waitlist: ', error);
	  }
	};
  
	return (
	<div className="container mt-4">
		<ThemeProvider theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
		  <title>EventRent</title>
		  <meta name={"description"} content={"Web site created using quarkly.io"} />
		  <link rel={"shortcut icon"} href={"https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/favicon-32x32.png?v=2024-05-20T19:02:10.953Z"} type={"image/x-icon"} />
		  <link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/apple-touch-icon.png?v=2024-05-20T19:01:06.036Z"} />
		  <link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/apple-touch-icon.png?v=2024-05-20T19:01:06.036Z"} />
		  <link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/apple-touch-icon.png?v=2024-05-20T19:01:06.036Z"} />
		  <link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/apple-touch-icon.png?v=2024-05-20T19:01:06.036Z"} />
		  <meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/android-chrome-512x512.png?v=2024-05-20T19:01:21.626Z"} />
		</Helmet>
		<Container sx={{ mt: 4, mb: 4, px: 2 }}>
		<Grid container spacing={4} alignItems="center" justifyContent="center">
			<Grid item xs={12} md={7} display="flex" alignItems="center" textAlign="center">
			  <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
				<Typography variant="h2" color="textPrimary" gutterBottom>
				  <strong>Effortless Event Planning with AI</strong>
				</Typography>
				<Typography variant="h6" color="textSecondary" paragraph>
					All-in-one platform with AI-powered planning, vendor matching, and automated approvals—saving you time and ensuring your event's success.
				</Typography>
				<Typography variant="h6" color="textSecondary" paragraph>
					Join our waitlist now and be the first to experience the future of event hosting!
				</Typography>
				<Box width="100%" maxWidth="400px" mt={4}>
				  <Typography variant="h4" color="textPrimary" gutterBottom>
					<strong>Sign Up</strong>
				  </Typography>
				  <div className={classes.signUpFormContainer}>
					{submitted ? (
					  <Typography variant="body1" className={classes.formDescription}>Thank you for joining the waitlist!</Typography>
					) : (
					  <form onSubmit={handleSubmit} className={classes.form}>
						<div className={classes.formGroup}>
						  <input 
							type="email" 
							value={email} 
							onChange={(e) => setEmail(e.target.value)} 
							placeholder="Email"
							required 
							className={classes.formInput}
						  />
						</div>
						<Button type="submit" variant="contained" className={classes.formButton}>Join Waitlist</Button>
					  </form>
					)}
				  </div>
				</Box>
			  </Box>
			</Grid>
			<Grid item xs={8} md={5} className={classes.imageContainer}>
			  <img
				src="https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/EventRent_%20copy.png?v=2024-05-20T18:28:05.840Z"
				alt="EventRent"
				style={{ maxWidth: '100%', maxHeight: '700px', alignItems:'center', transform: 'translateY(10px)', transition: 'transform 0.5s ease-in-out' }}
				onMouseOver={(e) => (e.currentTarget.style.transform = 'translateY(0px)')}
				onMouseOut={(e) => (e.currentTarget.style.transform = 'translateY(10px)')}
			  />
			</Grid>
		  </Grid>
		</Container>
		<Box py={4} bgcolor="background.paper">
		  <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
			<Box display="flex" alignItems="center">
			</Box>
		  </Box>
		</Box>
		<RawHtml>
		  <style place={"endOfHead"} rawKey={"661c27ce6bbd20002369bc15"}>
			{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
		  </style>
		</RawHtml>
	  </ThemeProvider>
	  </div>
	);
  };
  
  export default IndexPage;
