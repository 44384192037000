import React, {useContext, useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
// import Dashboard from '../dashboard/Dashboard';
import BudgetChart from '../components/myEvents/BudgetChart';
import { Box, Container, Grid, Typography, Card, CardContent, CardMedia, Button, Paper, List, ListItem, ListItemIcon, ListItemText, Chip } from '@mui/material';

import EventCard from '../components/myEvents/Host/EventCard';
import { UserContext } from '../contexts/UserContext';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BarChartIcon from '@mui/icons-material/BarChart';
import EventIcon from '@mui/icons-material/Event';
import PeopleIcon from '@mui/icons-material/People';

const events = [
  {
    type: 'upcoming',
    eventName: 'X Ventures Networking and Panel',
    venueName: 'Lume Studios, NYC',
    guestCount: 100,
    date: 'September 21, 2024',
    imageUrl: 'https://bvhldkvbwncfqndxjncl.supabase.co/storage/v1/object/public/venue-images-store/venues/db85687b-8049-40e4-a6f1-0ab3715afbf5/82f89f6d-b20a-47fd-8e9c-436558be8e80_LumeStudiosX.jpg',
  },
  {
    type: 'past',
    eventName: 'Tech Conference 2024',
    venueName: 'The Hammerstein',
    date: 'Sep 14–15, 2024',
    guestCount: 100,
    imageUrl: 'https://bvhldkvbwncfqndxjncl.supabase.co/storage/v1/object/public/venue-images-store/venues/1df04a0d-a4a2-4df5-b859-159db6d32fe3/96dc81ce-1f6e-4d29-a316-fe69adb3310a_manhattancenter-image005-1-min.jpg',
  },
  {
    type: 'past',
    eventName: 'Music Fest 2024',
    venueName: 'National Sawdust, NYC',
    date: 'June 21–22, 2023',
    guestCount: 100,
    imageUrl: 'https://bvhldkvbwncfqndxjncl.supabase.co/storage/v1/object/public/venue-images-store/venues/7d9dc3e5-1219-4ea4-b1ec-05b936abb4fc/Screenshot%202024-06-11%20at%202.53.00%20PM.png',
  },
];

const MyEventsSection = ({ username = 'Eliot' }) => {
  const { user } = useContext(UserContext); // Access user data from UserContext

  const history = useHistory();

  const handleClick = () => {
    history.push(`myevents/test`);
  };

  const handleEventCardClick = (event) => {
    history.push(`/myevents/${event.id}`);
  };

  const handleAddEvent = () => {
    history.push('/eventrentstartpage');
  };

  return (
    <div className="container mt-4"> 
    <Container sx={{ mt: 4, mb: 4 }}>
      {/* Welcome Section */}
      <Typography variant="h3" component="h1" sx={{ mb: 4 }}>
        Welcome {user?.given_name || 'Guest'}
      </Typography>

      <Box sx={{ my: 4 }}>
        <hr style={{ border: '1px solid #e0e0e0' }} />
      </Box>

      {/* Events Section */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" component="h2">
          Your Upcoming Events
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
          onClick={handleAddEvent}
          sx={{
            borderRadius: 20,
            textTransform: 'none',
            fontWeight: 'bold',
            boxShadow: 2,
          }}
        >
          Add Event
        </Button>
      </Box>

      <Grid container spacing={4}>
        {events
          .filter(event => event.type === 'upcoming')
          .map((event, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <EventCard event={event} onClick={handleClick} />
            </Grid>
          ))}
      </Grid>

      <Typography variant="h4" component="h2" gutterBottom sx={{ mt: 6 }}>
        Your Past Events
      </Typography>
      <Grid container spacing={4}>
        {events
          .filter(event => event.type === 'past')
          .map((event, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <EventCard event={event} onClick={handleEventCardClick} />
              
            </Grid>
          ))}
      </Grid>

      <Box sx={{ my: 4 }}>
        <hr style={{ border: '1px solid #e0e0e0' }} />
      </Box>

      {/* At A Glance Section */}
      <Paper elevation={3} sx={{ borderRadius: 2, mt: 6, p: 3 }}>
        <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
            <Typography variant="h6" component="h3" gutterBottom>
              At A Glance
            </Typography>
            <Box sx={{ p: 3, display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ textAlign: 'center' }}>
                <MonetizationOnIcon color="primary" sx={{ fontSize: 40, mb: 1 }} />
                <Typography variant="h6" gutterBottom>Total Spend</Typography>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>$12,000</Typography>
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <BarChartIcon color="secondary" sx={{ fontSize: 40, mb: 1 }} />
                <Typography variant="h6" gutterBottom>Avg Spend</Typography>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>$6,000</Typography>
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <EventIcon color="success" sx={{ fontSize: 40, mb: 1 }} />
                <Typography variant="h6" gutterBottom>Events</Typography>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>2</Typography>
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <PeopleIcon color="info" sx={{ fontSize: 40, mb: 1 }} />
                <Typography variant="h6" gutterBottom>Attendees</Typography>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>200</Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>

        {/* Budget Chart Section */}
        <Grid item xs={12} md={6}>
          <CardContent>
            <BudgetChart />
          </CardContent>
        </Grid>
        </Grid>
      </Paper>

      {/* Updated Recommendations Section */}
      <Grid item xs={12} sx={{ mt: 4 }}>
        <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <SmartToyIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold' }}>
              AI-Powered Recommendations
            </Typography>
          </Box>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Based on your past events, our AI suggests the following improvements:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <AccessTimeIcon color="primary" />
              </ListItemIcon>
              <ListItemText 
                primary="Improve Response Time" 
                secondary="Aim to respond to vendors within 24 hours. Current average: 2 days."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ErrorOutlineIcon color="error" />
              </ListItemIcon>
              <ListItemText 
                primary="Proactive Issue Resolution" 
                secondary="3 core issues were resolved in the last 48 hours. Consider earlier planning to prevent last-minute problem-solving."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <MonetizationOnIcon color="success" />
              </ListItemIcon>
              <ListItemText 
                primary="Optimize Costs" 
                secondary="Utilize our partnerships feature to decrease overall event costs by an estimated 15%."
              />
            </ListItem>
          </List>
        </Paper>
      </Grid>

      {/* Dashboard component remains commented out */}
    </Container>
    </div>
  );
};

export default MyEventsSection;
