// PrivateRoute.js
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from './contexts/UserContext';

const PrivateRoute = ({ component: Component, roles: allowedRoles, ...rest }) => {
  const { roles } = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        // Check if user has one of the allowed roles
        const hasAccess = roles.some((role) => allowedRoles.includes(role));

        return hasAccess ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" /> // Redirect if no access
        );
      }}
    />
  );
};

export default PrivateRoute;
