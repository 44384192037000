import Index from "pages/index";
import Page404 from "pages/page404";
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import EventRentStartPage from "pages/EventRentStartPage";
import VenueFormPage from "pages/VenueOwner/AddVenue";
import VenuePage from './pages/VenuePage';
import MarketplacePage from "pages/marketplace";
// import MyVenues from "components/UserProfile/MyVenues";
import ShoppingCartPage from "pages/ShoppingCart";
import Box from '@mui/material/Box';
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import EventHostNavBar from "components/NavBar/EventHostNavBar";
import StripeCheckoutSuccessPage from "pages/StripeCheckoutSuccessRoute";
import MyEventsAnalytics from "components/myEvents/Host/MyEventAnalytics";
import MyEventsSection from "pages/myEventsDashboardPage";
// import MyEventsSection from "pages/myEventsDashboardPage";
import PrivateRoute from "./PrivateRoute";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


import { googleLogout } from "@react-oauth/google";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import EventDetailsPage from "components/myEvents/Host/eventDetailsPage";
import ScrollToTop from "components/ScrollToTop";
import ConditionalNavBar from "components/NavBar/ConditionalNavBar";
import HostYourVenueWithUs from "pages/VenueOwner/HostYourVenueWithUs";
import MyVenues from "pages/VenueOwner/MyVenues";
import EventRequests from "pages/VenueOwner/EventRequests";
import UserProfilePage from "pages/UserProfilePage";

import { UserProvider } from './contexts/UserContext';

function App() {
    const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
    }
    `;

    // Initialize state from localStorage
    const [cartCount, setCartCount] = useState(() => {
        const savedCount = localStorage.getItem('cartCount');
        return savedCount ? parseInt(savedCount, 10) : 0;
    });

    // Update localStorage whenever cartCount changes
    useEffect(() => {
        // Read from localStorage every time the component renders
        const savedCount = localStorage.getItem('cartCount');
        setCartCount(savedCount ? parseInt(savedCount, 10) : 0);
    }, []);

    useEffect(() => {
        localStorage.setItem('cartCount', cartCount);
    }, [cartCount]);

    const resetCartCount = () => {
        localStorage.setItem('cartCount', 0);
        setCartCount(0);
    };

    const [cartAddons, setCartAddons] = useState([]);
    const [venue, setVenues] = useState([]);

    return (
        <UserProvider>
            <Router>
                <ToastContainer />
                <ScrollToTop />
                <GlobalStyles />
                <Box mt={6}>
                    <ConditionalNavBar cartCount={cartCount}/>
                </Box>
                <Switch>
                    <Route exact path='/' component={Index}/>
                    <Route exact path='/index' component={Index}/>
                    <Route exact path='/EventRentStartPage' component={EventRentStartPage}/>
                    <PrivateRoute exact path='/marketplace' component={MarketplacePage} roles={['admin', 'host', 'test']}/>
                    {/* <Route exact path='/marketplace' component={MarketplacePage}/> */}
                    <Route exact path='/addVenue' component={VenueFormPage}/>
                    <Route exact path='/profile' component={UserProfilePage}/>
                    {/* <Route exact path='/MyVenues' component={MyVenues}/> */}
                    <Route
                        path="/venue/:id"
                        render={(props) => (
                            <VenuePage {...props} setCartCount={setCartCount} setCartAddonItems={setCartAddons} setCartVenueItems={setVenues}/>
                        )}
                    />
                    <Route
                        path="/shoppingcart"
                        render={(props) => (
                            <ShoppingCartPage {...props} cartAddons={cartAddons} venue={venue}/>
                        )}
                    />
                    <Route
                        exact path='/stripeCheckoutSuccessPage'
                        render={(props) => (
                            <StripeCheckoutSuccessPage {...props} resetCartCount={resetCartCount} />
                        )}
                    />
                    <Route exact path='/eventanalytics' component={MyEventsAnalytics}/>
                    <Route exact path='/myevents' component={MyEventsSection}/>

                    <Route exact path="/venueOwner/LandingPage" component={HostYourVenueWithUs} />
                    <Route exact path="/myevents/test" component={EventDetailsPage} />
                    <Route exact path="/venueOwner/addVenue" component={VenueFormPage} />
                    <Route exact path="/venueOwner/myEvents" component={MyEventsSection} />
                    <Route exact path="/venueOwner/myVenues" component={MyVenues} />
                    <Route exact path="/venueOwner/EventRequests" component={EventRequests} />
                    <Route component={Page404}/>
                </Switch>
            </Router>
        </UserProvider>
    );
}
export default App;