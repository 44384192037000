// MessageList.jsx
import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Paper, Avatar, AvatarGroup, Chip, Link } from '@mui/material';
import MessageInput from './MessageInput';
import ProfileIcon from './ProfileIcon';
import InternalLink from './InternalLink';

function UtteranceList({ conversation, onTagClick }) {
  const [messages, setMessages] = useState(conversation.messages);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setMessages(conversation.messages);
  }, [conversation]);

  const handleSendMessage = (content) => {
    const newMessage = {
      sender: 'Current User',
      content: content.message,
      tags: content.tags,
      timestamp: new Date().toISOString(),
      isCurrentUser: true,
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setTags([]); // Clear tags after sending
    // Update backend here
  };

  const handleAddTag = (tag) => {
    if (!tags.some(t => t.label === tag.label)) {
      setTags([...tags, tag]);
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter(tag => tag.label !== tagToRemove.label));
  };

  const otherParticipant = conversation.participants.find(p => p.name !== 'Current User');

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Paper elevation={1} sx={{ p: 2, borderRadius: 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant="h6">{otherParticipant.name}</Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {otherParticipant.company} - {otherParticipant.industry}
            </Typography>
          </Box>
          <AvatarGroup max={2}>
            {conversation.participants.map((participant, index) => (
              <Avatar key={index} alt={participant.name}>
                {participant.name.charAt(0)}
              </Avatar>
            ))}
          </AvatarGroup>
        </Box>
      </Paper>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2 }}>
        <List>
          {messages.map((msg, index) => (
            <ListItem
              key={index}
              sx={{
                justifyContent: msg.isCurrentUser ? 'flex-end' : 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              {!msg.isCurrentUser && (
                <Box sx={{ mr: 1 }}>
                  <ProfileIcon user={msg.sender} />
                </Box>
              )}
              <ListItemText
                primary={
                  <Paper
                    sx={{
                      p: 1,
                      backgroundColor: msg.isCurrentUser ? 'primary.main' : 'grey.200',
                      color: msg.isCurrentUser ? 'white' : 'text.primary',
                      borderRadius: msg.isCurrentUser ? '20px 20px 0 20px' : '20px 20px 20px 0',
                    }}
                  >
                    {msg.content}
                    {msg.tags && msg.tags.length > 0 && (
                      <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {msg.tags.map((tag, tagIndex) => (
                          <InternalLink
                            key={tagIndex}
                            label={tag.label}
                            onClick={() => onTagClick(tag.label)}
                            size="small"
                            sx={{ 
                              backgroundColor: msg.isCurrentUser ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.1)',
                              color: msg.isCurrentUser ? 'white' : 'text.primary',
                              cursor: 'pointer',
                            }}
                          />
                        ))}
                      </Box>
                    )}
                  </Paper>
                }
                secondary={
                  <Typography variant="caption" sx={{ mt: 0.5, display: 'block', textAlign: msg.isCurrentUser ? 'right' : 'left' }}>
                    {new Date(msg.timestamp).toLocaleTimeString()}
                  </Typography>
                }
                sx={{ maxWidth: '70%' }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <MessageInput 
        onSendMessage={handleSendMessage} 
        tags={tags} 
        onRemoveTag={handleRemoveTag}
      />
    </Box>
  );
}

export default UtteranceList;
