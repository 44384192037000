import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, LinearProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { blue } from '@mui/material/colors';

const DocumentUploader = ({ onUploadComplete }) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFile(null);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) return;

    setUploading(true);
    // Simulating file upload and parsing
    await new Promise(resolve => setTimeout(resolve, 2000));

    // Here you would typically send the file to your backend for parsing
    // For this example, we'll simulate parsed data
    const parsedData = {
      vision: "A networking event connecting top industry leaders.",
      audience: "Founders, VCs, Software Engineers",
      eventType: "Corporate Networking, Panel Discussion",
      // Add more fields as needed
    };

    setUploading(false);
    handleClose();
    onUploadComplete(parsedData);
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<CloudUploadIcon />}
        onClick={handleClickOpen}
        sx={{
          bgcolor: blue[700],
          '&:hover': { bgcolor: blue[800] },
          color: 'white'
        }}
      >
        Upload Event Documents
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload Event Documents</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Upload a PDF, Word document, or Event Pitch deck to automatically fill out event details.
          </Typography>
          <input
            accept=".pdf,.doc,.docx,.ppt,.pptx"
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="raised-button-file">
            <Button variant="outlined" component="span">
              Choose File
            </Button>
          </label>
          {file && <Typography variant="body2">{file.name}</Typography>}
          {uploading && <LinearProgress sx={{ mt: 2 }} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUpload} disabled={!file || uploading}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DocumentUploader;