import React from 'react';
import { Card, CardContent, TextField, Button, IconButton, Divider, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GoogleSignIn from './googleLogin';

const DividerWithText = ({ children }) => {
  const styles = {
    container: {
      display: "flex",
      alignItems: "center"
    },
    border: {
      borderBottom: "2px solid lightgray",
      width: "100%"
    },
    content: {
      paddingTop: 4,
      paddingBottom: 4,
      paddingRight: 8,
      paddingLeft: 8,
      fontWeight: 500,
      fontSize: 22,
      color: "lightgray"
    }
  };
  return (
    <div style={styles.container}>
      <div style={styles.border} />
      <span style={styles.content}>{children}</span>
      <div style={styles.border} />
    </div>
  );
};

const LoginCard = ({ onClose, onUserLogin }) => (
  <Box 
    sx={{ 
      position: 'fixed', 
      top: 0, 
      left: 0, 
      width: '100%', 
      height: '100%', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center', 
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // dimmed background
      zIndex: 1300, // ensures the overlay is on top
    }}
  >
    <Card 
      sx={{ 
        width: 400, 
        height: 400, // making it a square
        borderRadius: 2, // rounded corners
        position: 'relative',
      }}
    >
      <CardContent sx={{ 
        position: 'relative', 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
      }}>
        {/* Top bar with close button and title */}
        <Box 
          sx={{ 
            position: 'absolute', 
            top: 0, 
            left: 0, 
            right: 0, 
            bgcolor: 'background.paper', 
            color: 'text.primary', 
            py: 1, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center' 
          }}
        >
          <IconButton 
            sx={{ position: 'absolute', left: 8, color: 'text.primary' }}
            aria-label="close"
            onClick={onClose} // Use the onClose prop to handle the close action
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Log in or sign up</Typography>
        </Box>

        {/* Content area */}
        <Box 
          sx={{ 
            pt: 4, 
            display: 'flex', 
            flexDirection: 'column', 
            height: '100%', 
            justifyContent: 'center',
            px: 2 
          }}
        >
          <TextField 
            fullWidth 
            label="Username" 
            variant="outlined" 
            margin="normal" 
          />
          <TextField 
            fullWidth 
            label="Password" 
            type="password" 
            variant="outlined" 
            margin="normal" 
          />
          <Button 
            variant="contained" 
            color="primary" 
            fullWidth 
            sx={{ mt: 2 }}
          >
            Continue
          </Button>
          <DividerWithText>or</DividerWithText>
          <GoogleSignIn onUserLogin={onUserLogin}/>
        </Box>
      </CardContent>
    </Card>
  </Box>
);

export default LoginCard;
