import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { Box, Button, Popover, Typography } from '@mui/material';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const DateFilter = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [anchorEl, setAnchorEl] = useState(null);

    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
    };

    const handleSelect = (ranges) => {
        setStartDate(ranges.selection.startDate);
        setEndDate(ranges.selection.endDate);
        handleClose();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'date-popover' : undefined;

    return (
        <div>
            <Button variant="outlined" onClick={handleClick}>
                Select Date Range
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box p={2}>
                    <Typography variant="h6" gutterBottom>
                        Select Date Range
                    </Typography>
                    <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
                </Box>
            </Popover>
        </div>
    );
};

export default DateFilter;
