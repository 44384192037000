// data/conversations.js
export const events = [
  {
    id: 1,
    eventName: 'X Ventures Networking and Panel',
    conversations: [
      {
        id: 1,
        participants: [
          { name: 'Joe Smith', company: 'Tastings NYC | SoFlo Catering', industry: 'Catering' },
          { name: 'Current User', company: 'X Ventures', industry: 'Event Management' }
        ],
        lastMessage: 'Can you provide details on the kitchen and area setup for catering?',
        timestamp: '2024-09-29T14:00:00Z',
        unread: true,
        messages: [
          {
            sender: 'Joe Smith',
            content: 'Can you provide details on the kitchen and area setup for catering?',
            timestamp: '2024-09-29T14:00:00Z',
            isCurrentUser: false,
          },
          {
            sender: 'Current User',
            content: 'Sure, I will get the details from the venue and send them over.',
            timestamp: '2024-09-29T14:05:00Z',
            isCurrentUser: true,
            tags: [{ label: 'Target Audience', link: '#target-audience' }],
          },
        ],
      },
      {
        id: 1,
        participants: [
          { name: 'Susan Smith', company: 'Premium Pictures', industry: 'Photography' },
          { name: 'Current User', company: 'X Ventures', industry: 'Event Management' }
        ],
        lastMessage: 'Can you provide details on the photography agenda, such as arrival time and setup?',
        timestamp: '2024-09-29T14:00:00Z',
        unread: true,
        messages: [
          {
            sender: 'Susan Smith',
            content: 'Can you provide details on the photography agenda, such as arrival time and setup?',
            timestamp: '2024-09-29T14:00:00Z',
            isCurrentUser: false,
          },
          {
            sender: 'Current User',
            content: 'Sure, I have just added this to the agenda.',
            timestamp: '2024-09-29T14:05:00Z',
            isCurrentUser: true,
            tags: [{ label: 'Agenda', link: '#target-audience' }],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    eventName: 'X Ventures Private Dinner',
    conversations: [
      {
        id: 2,
        participants: [
          { name: 'Sarah Lee', company: 'Premium Photography', industry: 'Photography' },
          { name: 'Current User', company: 'X Ventures', industry: 'Event Management' }
        ],
        lastMessage: 'Could you please confirm the arrival time and provide the detailed agenda?',
        timestamp: '2024-10-05T09:30:00Z',
        unread: true,
        messages: [
          {
            sender: 'Sarah Lee',
            content: 'Hi there! I\'m Sarah from Premium Photography. Could you please confirm the arrival time for the Adventures Private Dinner event?',
            timestamp: '2024-10-05T09:30:00Z',
            isCurrentUser: false,
          },
          {
            sender: 'Current User',
            content: 'Hello Sarah! Thanks for reaching out. The arrival time for setup is 5:00 PM. The dinner starts at 7:00 PM.',
            timestamp: '2024-10-05T09:45:00Z',
            isCurrentUser: true,
          },
          {
            sender: 'Sarah Lee',
            content: 'Great, thank you! Could you also provide the detailed agenda for the evening? It would help us plan our shot list.',
            timestamp: '2024-10-05T10:00:00Z',
            isCurrentUser: false,
          },
          {
            sender: 'Current User',
            content: 'Of course! Here\'s a brief outline:\n5:00 PM - 7:00 PM: Setup\n7:00 PM - 7:30 PM: Guest arrival and cocktails\n7:30 PM - 8:30 PM: Dinner\n8:30 PM - 9:30 PM: Keynote speech and Q&A\n9:30 PM - 10:30 PM: Networking\n10:30 PM: Event concludes',
            timestamp: '2024-10-05T10:15:00Z',
            isCurrentUser: true,
          },
          {
            sender: 'Sarah Lee',
            content: 'This is perfect, thank you so much! We\'ll make sure to capture all the key moments. Is there anything specific you want us to focus on?',
            timestamp: '2024-10-05T10:30:00Z',
            isCurrentUser: false,
          },
        ],
      },
    ],
  },
];