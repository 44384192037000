import React, { useState, useEffect, useContext} from 'react';
import { Box, Typography, TextField, Button, Paper, Grid, Divider } from '@mui/material';
import { styled } from '@mui/system';

import { UserContext } from '../contexts/UserContext';

const SidebarButton = styled(Button)(({ theme, active }) => ({
  justifyContent: 'flex-start',
  padding: theme.spacing(1, 2),
  textAlign: 'left',
  backgroundColor: active ? theme.palette.action.selected : 'transparent',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const UserProfilePage = () => {
  const { user, setUser } = useContext(UserContext);
  const [activeSection, setActiveSection] = useState('Account');
  const [userInfo, setUserInfo] = useState({
    given_name: '',
    family_name: '',
    preferredName: '',
    companyAffiliation: '',
    email: '',
    address: '',
  });

  useEffect(() => {
    console.log(user);
    if (user) {
        setUserInfo({
            given_name: user?.given_name || '',
            family_name: user?.family_name || '',
            preferredName: user?.preferredName || '',
            companyAffiliation: user?.companyAffiliation || '',
            email: user?.email || '',
            address: user?.address || '',
        });
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      //TODO: Add backend call to update user info
      //TODO: Add error handling

      
      // Handle form submission, e.g., send updated info to backend
    //   const response = await fetch('/api/update-user', {
    //     method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(userInfo),
    // });

    // const result = await response.json();
    // if (response.ok) {
    //   // Handle form submission for local storage and user context
    //   setUser(result.updatedUser); // Update user context with new data
    //   localStorage.setItem('userData', JSON.stringify(result.updatedUser));
    //   alert('Profile updated successfully!');
    // } else {
    //   throw new Error(result.error);
    // }

    // Handle form submission, e.g., send updated info to backend
    console.log('Updated user info:', userInfo);
    // You would typically make an API call here to update the user's information
    const updatedUser = { ...user, ...userInfo }; 
    setUser(updatedUser);
    localStorage.setItem('userData', JSON.stringify(updatedUser));

    } catch (error) {
    console.error('Error updating profile:', error);
  }
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', bgcolor: 'background.default' }}>
      {/* Sidebar */}
      <Paper elevation={3} sx={{ width: 200, p: 2, display: { xs: 'none', sm: 'block' } }}>
        <Typography variant="h6" sx={{ mb: 2 }}>User Profile</Typography>
        <SidebarButton
          fullWidth
          active={activeSection === 'Account'}
          onClick={() => setActiveSection('Account')}
        >
          Account
        </SidebarButton>
        <SidebarButton
          fullWidth
          active={activeSection === 'Settings'}
          onClick={() => setActiveSection('Settings')}
        >
          Settings
        </SidebarButton>
        <SidebarButton
          fullWidth
          active={activeSection === 'Contact Us'}
          onClick={() => setActiveSection('Contact Us')}
        >
          Contact Us
        </SidebarButton>
        <SidebarButton
          fullWidth
          active={activeSection === 'Privacy'}
          onClick={() => setActiveSection('Privacy')}
        >
          Privacy
        </SidebarButton>
      </Paper>

      {/* Main content */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h4" sx={{ mb: 4 }}>Account Information</Typography>
        <Paper elevation={3} sx={{ p: 3, maxWidth: 600, mx: 'auto' }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="First Name"
                  name="given_name"
                  value={userInfo.given_name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Last Name"
                  name="family_name"
                  value={userInfo.family_name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Preferred Name"
                  name="preferred_name"
                  value={userInfo.preferred_name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Company Affiliation"
                  name="company_affiliation"
                  value={userInfo.company_affiliation}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={userInfo.email}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Address"
                  name="address"
                  value={userInfo.address}
                  onChange={handleInputChange}
                  multiline
                  rows={2}
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Box>
    </Box>
  );
};

export default UserProfilePage;