import React, { useState, useRef } from 'react';
import { Link, Box, Paper, Card, CardContent, CardMedia, Typography, TextField, Chip, IconButton, List, ListItem, ListItemText, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { styled, keyframes } from '@mui/material/styles';

import VenueBox from '../../Venue/VenueBox';
import AddonsGrid from './AddonsGrid';
import SponsorGrid from './SponsorGrid';

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(25, 118, 210, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0);
  }
`;

const HighlightedField = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  animation: `${pulseAnimation} 2s infinite`,
  transition: 'all 0.3s ease-in-out',
}));

const EventDetails = ({ eventData, setEventData }) => {
  const [editingSections, setEditingSections] = useState({
    basics: false,
    audience: false,
    format: false,
    speakers: false,
    marketing: false,
  });

  const targetAudienceRef = useRef(null);
  const audienceInfoRef = useRef(null);
  const venueRef = useRef(null);
  const marketingRef = useRef(null);

  const [highlightedField, setHighlightedField] = useState(null);
  const [highlightedSection, setHighlightedSection] = useState(null);

  const handleEdit = (section) => {
    setEditingSections(prev => ({ ...prev, [section]: true }));
  };

  const handleSave = (section) => {
    setEditingSections(prev => ({ ...prev, [section]: false }));
    // TODO: Here you would typically save the changes to your backend
  };

  const handleChange = (section, field, value) => {
    setEventData(prevData => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value
      }
    }));
  };

  const audienceData = [
    { name: 'Founders', value: 30 },
    { name: 'VCs', value: 20 },
    { name: 'Software Engineers', value: 40 },
    { name: 'Others', value: 10 },
  ];
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const renderSectionHeader = (title, section) => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
      <Typography variant="h6">{title}</Typography>
      <IconButton onClick={() => editingSections[section] ? handleSave(section) : handleEdit(section)}>
        {editingSections[section] ? <SaveIcon /> : <EditIcon />}
      </IconButton>
    </Box>
  );

  const totalCost = (eventData.cost 
    + eventData.addons.reduce((sum, addon) => sum + addon.price, 0)
    - eventData.sponsors.reduce((sum, sponsor) => sum + sponsor.price, 0)).toLocaleString();

  const handleTagClick = (tagName) => {
    console.log('Tag clicked:', tagName);
    let ref = null;
    let field = null;

    switch (tagName) {
      case 'Target Audience':
        ref = targetAudienceRef;
        field = 'target';
        break;
      case 'Audience Information':
        ref = audienceInfoRef;
        setHighlightedSection('audience');
        break;
      case 'Venue':
        ref = venueRef;
        setHighlightedSection('venue');
        break;
      case 'Marketing and Promotion':
        ref = marketingRef;
        setHighlightedSection('marketing');
        break;
      default:
        break;
    }

    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    if (field) {
      setHighlightedField(field);
    }

    setTimeout(() => {
      setHighlightedField(null);
      setHighlightedSection(null);
    }, 3000);
  };

  const renderTextField = (section, field, label) => {
    const isHighlighted = highlightedField === field;
    console.log(`Rendering ${field}, isHighlighted:`, isHighlighted);
    
    return (
      <Box key={`${section}-${field}`} sx={{ position: 'relative' }}>
        <HighlightedField 
          sx={{ 
            opacity: isHighlighted ? 1 : 0,
            pointerEvents: 'none',
            position: 'absolute',
            top: -4,
            left: -4,
            right: -4,
            bottom: -4,
            zIndex: 1,
          }}
        />
        <TextField
          fullWidth
          margin="normal"
          label={label}
          value={eventData[section][field]}
          onChange={(e) => handleChange(section, field, e.target.value)}
          disabled={!editingSections[section]}
          id={`${section}-${field}`}
          inputRef={field === 'target' ? targetAudienceRef : null}
          sx={{ position: 'relative', zIndex: 2 }}
        />
      </Box>
    );
  };

  return (
    <Grid container spacing={3}>
      {/* Venue Details */}
      <Grid item xs={12} md={6}>
        <VenueBox 
          venueData={eventData.venue}
          highlightedSection={highlightedSection}
          venueRef={venueRef}
        />
      </Grid>

      {/* Event Basics */}
      <Grid item xs={12} md={6} id="Event-Basics-section">
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        {renderSectionHeader('Event Basics', 'basics')}
        <TextField
          fullWidth
          margin="normal"
          label="Event Name"
          value={eventData.basics.name}
          onChange={(e) => handleChange('basics', 'name', e.target.value)}
          disabled={!editingSections.basics}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Date and Time"
          value={`${eventData.basics.date} ${eventData.basics.time}`}
          onChange={(e) => {
            const [date, time] = e.target.value.split(' ');
            handleChange('basics', 'date', date);
            handleChange('basics', 'time', time);
          }}
          disabled={!editingSections.basics}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Duration"
          value={eventData.basics.duration}
          onChange={(e) => handleChange('basics', 'duration', e.target.value)}
          disabled={!editingSections.basics}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Vision/Purpose"
          value={eventData.basics.vision}
          onChange={(e) => handleChange('basics', 'vision', e.target.value)}
          disabled={!editingSections.basics}
          multiline
          rows={2}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Event Type"
          value={eventData.basics.type}
          onChange={(e) => handleChange('basics', 'type', e.target.value)}
          disabled={!editingSections.basics}
        />
      </Paper>
      </Grid>

      {/* Add-ons Section */}
      <AddonsGrid eventDetails={eventData} onTagClick={handleTagClick} />

      {/* Audience Information */}
      <Grid item xs={12} md={6}>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }} ref={audienceInfoRef}>
        {renderSectionHeader('Audience Information', 'audience')}
        <Grid container spacing={3} sx={{
          transition: 'all 0.3s ease-in-out',
          ...(highlightedSection === 'audience' && {
            backgroundColor: 'rgba(25, 118, 210, 0.1)',
            borderRadius: '4px',
          }),
        }}>
          <Grid item xs={12} md={6}>
            {renderTextField('audience', 'target', 'Target Audience')}
            <TextField
              fullWidth
              margin="normal"
              label="Expected Attendees"
              type="number"
              value={eventData.audience.expectedAttendees}
              onChange={(e) => handleChange('audience', 'expectedAttendees', parseInt(e.target.value))}
              disabled={!editingSections.audience}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Age Range"
              value={eventData.audience.demographics.ageRange}
              onChange={(e) => handleChange('audience', 'demographics', { ...eventData.audience.demographics, ageRange: e.target.value })}
              disabled={!editingSections.audience}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Professional Background"
              value={eventData.audience.demographics.background}
              onChange={(e) => handleChange('audience', 'demographics', { ...eventData.audience.demographics, background: e.target.value })}
              disabled={!editingSections.audience}
              multiline
              rows={2}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>Audience Breakdown</Typography>
            <Box sx={{ width: '100%', height: 300 }}>
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={audienceData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {audienceData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      </Grid>

      {/* Sponsors Section */}
      <SponsorGrid eventDetails={eventData} />

      {/* Event Format */}
      <Grid item xs={12} md={6}>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        {renderSectionHeader('Event Format', 'format')}
        <TextField
          fullWidth
          margin="normal"
          label="Agenda Overview"
          value={eventData.format.agenda}
          onChange={(e) => handleChange('format', 'agenda', e.target.value)}
          disabled={!editingSections.format}
          multiline
          rows={2}
        />
        <Typography variant="subtitle1" gutterBottom>Key Activities</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {eventData.format.keyActivities.map((activity, index) => (
            <Chip key={index} label={activity} onDelete={editingSections.format ? () => {} : undefined} />
          ))}
        </Box>
        <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>Special Features</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {eventData.format.specialFeatures.map((feature, index) => (
            <Chip key={index} label={feature} onDelete={editingSections.format ? () => {} : undefined} />
          ))}
        </Box>
      </Paper>
      </Grid>

      {/* Speakers and Presenters */}
      <Grid item xs={12} md={6}>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        {renderSectionHeader('Speakers and Presenters', 'speakers')}
        <List>
          {eventData.speakers.map((speaker, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={speaker.name}
                secondary={speaker.bio}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
      </Grid>

      {/* Marketing and Promotion */}
      <Grid item xs={12} md={6}>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }} ref={marketingRef}>
        {renderSectionHeader('Marketing and Promotion', 'marketing')}
        <Box sx={{
          transition: 'all 0.3s ease-in-out',
          ...(highlightedSection === 'marketing' && {
            backgroundColor: 'rgba(25, 118, 210, 0.1)',
            borderRadius: '4px',
            padding: '8px',
          }),
        }}>
          <Typography variant="subtitle1" gutterBottom>Marketing Channels</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
            {eventData.marketing.channels.map((channel, index) => (
              <Chip key={index} label={channel} onDelete={editingSections.marketing ? () => {} : undefined} />
            ))}
          </Box>
          <TextField
            fullWidth
            margin="normal"
            label="Promotional Strategy"
            value={eventData.marketing.strategy}
            onChange={(e) => handleChange('marketing', 'strategy', e.target.value)}
            disabled={!editingSections.marketing}
            multiline
            rows={2}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Expected Media Coverage"
            value={eventData.marketing.expectedMediaCoverage}
            onChange={(e) => handleChange('marketing', 'expectedMediaCoverage', e.target.value)}
            disabled={!editingSections.marketing}
            multiline
            rows={2}
          />
        </Box>
      </Paper>
      </Grid>

      {/* Total Cost Section */}
      <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              Total Costs Breakdown
            </Typography>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography variant="body1">Venue:</Typography>
              <Typography variant="body1">${eventData.cost.toLocaleString()}</Typography>
            </Box>
            {eventData.addons.map((addon, index) => (
              <Box key={index} sx={{ pl: 2 }} mb={2}>
                <Typography variant="body2" display="block" gutterBottom>
                  {addon.category}: ${addon.price.toLocaleString()}
                </Typography>
                <Typography variant="body2" color="textSecondary">{addon.name}</Typography>
              </Box>
            ))}
            {eventData.sponsors.map((sponsor, index) => (
              <Box key={index} display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="body1" sx={{ color: 'green' }}>{sponsor.name}:</Typography>
                <Typography variant="body1" sx={{ color: 'green' }}>-${sponsor.price.toLocaleString()}</Typography>
              </Box>
            ))}
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Typography variant="h6">Total Cost After Sponsorships</Typography>
              <Typography variant="h6">${totalCost}</Typography>
            </Box>
          </Paper>
        </Grid>
    </Grid>
  );
};

export default EventDetails;