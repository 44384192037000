import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import classes from '../components/collaborators.module.css';

import { Box, Typography, Button, Divider, Grid, Paper, TextField, IconButton } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CardRow from 'components/Collaborators/CardRow';
import CollaboratorModal from 'components/Collaborators/CollaboratorModal';


const ShoppingCartPage = ({cartAddonItems}) => {
  const location = useLocation();
  const { cartAddons, venue } = location.state || {};

  const [cartItems, setCartItems] = useState(cartAddonItems || []);
  const [recommendedAddons, setRecommendedAddons] = useState([]);
  const [recommendedSponsors, setRecommendedSponsors] = useState([]);
  const [error, setError] = useState('');

  const [totalPrice, setTotalPrice] = useState(0);
  //Modal 
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', subtitle: '', description: '', price: 0 });

  //Guest Count
  const [guestCount, setGuestCount] = useState(150);
  const [isEditingGuest, setIsEditingGuest] = useState(false);
  const [newGuestCount, setNewGuestCount] = useState(guestCount);

  //Event Vision
  const [eventVision, setEventVision] = useState('A grand networking event connecting top industry leaders.');
  const [isEditingVision, setIsEditingVision] = useState(false);
  const [newEventVision, setNewEventVision] = useState(eventVision);

  //Event Target Audience 
  const [targetAudience, setTargetAudience] = useState('Founders, VCs, Software Engineers');
  const [isEditingAudience, setIsEditingAudience] = useState(false);
  const [newTargetAudience, setNewTargetAudience] = useState(targetAudience);

  // Handlers for Guest Count
  const handleEditGuestClick = () => setIsEditingGuest(true);
  const handleSaveGuestClick = () => {
    setGuestCount(newGuestCount);
    setIsEditingGuest(false);
  };
  const handleCancelGuestClick = () => setIsEditingGuest(false);


  // Handlers for Event Vision
  const handleEditVisionClick = () => setIsEditingVision(true);
  const handleSaveVisionClick = () => {
    setEventVision(newEventVision);
    setIsEditingVision(false);
  };
  const handleCancelVisionClick = () => setIsEditingVision(false);


  // Handlers for Target Audience
  const handleEditAudienceClick = () => setIsEditingAudience(true);
  const handleSaveAudienceClick = () => {
    setTargetAudience(newTargetAudience);
    setIsEditingAudience(false);
  };
  const handleCancelAudienceClick = () => setIsEditingAudience(false);

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        if (cartAddons) {
          setCartItems(cartAddons);
        }
  
        if (venue && venue.VenueAddOns) {
          setRecommendedAddons(venue.VenueAddOns);
          setRecommendedSponsors(venue.VenueSponsors);
        }
        console.log("cartAddons", cartAddons);
      } catch (err) {
        setError(err.message);
      }
    };
  
    fetchCartItems();
  }, [cartAddons, venue]);
  

  const handleQuickAdd = (addon) => {
    setCartItems((prevCartItems) => [...prevCartItems, addon]);
    toast.success(`${addon.companyName} added to cart`);
  };

  const handleRemoveAddon = (addonId) => {
    setCartItems((prevCartItems) => prevCartItems.filter(item => item.addonId !== addonId));
    toast.info('Addon removed from cart');
  };

  
  const handleCheckoutButtonClick = async () => {
    // history.push(`/create_checkout_session/create_checkout_session`);
    window.location.href = "/create_checkout_session/create_checkout_session"
  };

  const handleOpenModal = (collaborator) => {
    const title = collaborator.companyName;
    const subtitle = collaborator.addonType;
    const description = collaborator.desc;
    const price = collaborator.price;
    setModalContent({ title, subtitle, description, price });
    console.log("HANDLE OPEN ----- ", modalContent);
    // setSelectedCollaborator(collaborator);
    setModalOpen(true);
  };

  const handleCloseModal = () => setModalOpen(false);

  const handleAddAddonFromModal = () => {
    const newAddon = {
      addonId: String(new Date().getTime()),
      venueId: venue.venueId,
      created: new Date().toISOString(),
      companyName: modalContent.title,
      addonType: modalContent.subtitle,
      desc: modalContent.description,
      price: modalContent.price,
      cost: true,
    };
    console.log("HandleAddAddon from Modal 'Add to Cart'  from collaborators", newAddon);
    setCartItems([...cartItems, newAddon]);
    toast.success(`${modalContent.title} added to event`);
    console.log("cartItems", cartItems);
    handleCloseModal();
  };

  // const scrollToCart = () => {
  //   if (CartRef.current) {
  //     CartRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };

  const calculateTotal = () => {
    const addonsTotal = cartItems.reduce((total, item) => total + item.price, 0);
    const venuePrice = venue ? venue.startPrice : 0;
    return addonsTotal + venuePrice;
  };

  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <Box sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Request to book
        </Typography>

        <Paper elevation={1} sx={{ p: 2, mb: 4 }}>
          <Typography variant="body1" gutterBottom>
            Lower price.
          </Typography>
          <Typography variant="body2">
            Your dates are $373 less than the avg. rate of the last 60 days.
          </Typography>
        </Paper>

        <Grid container spacing={4}>
          <Grid item xs={12} md={7}>
            <Paper elevation={1} sx={{ p: 2, mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                Your Booking
              </Typography>
              <Divider sx={{ my: 2 }} />

              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="body1" sx={{ fontWeight: 'bold'}}>Dates</Typography>
                <Typography variant="body1">Aug 23 – 24</Typography>
                <Button variant="text">Edit</Button>
              </Box>

              {/* Guest Section */}
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="body1" sx={{ fontWeight: 'bold'}}>Guests</Typography>
          {isEditingGuest ? (
            <Box display="flex" alignItems="center">
              <TextField
                type="number"
                value={newGuestCount}
                onChange={(e) => setNewGuestCount(e.target.value)}
                size="small"
                style={{ width: '120px', marginRight: '10px' }}
              />
              <Button variant="text" onClick={handleSaveGuestClick}>Save</Button>
              <Button variant="text" onClick={handleCancelGuestClick}>Cancel</Button>
            </Box>
          ) : (
            <Typography variant="body1">{guestCount} guest{guestCount > 1 ? 's' : ''}</Typography>
          )}
          {!isEditingGuest && <Button variant="text" onClick={handleEditGuestClick}>Edit</Button>}
        </Box>

            </Paper>

            <Paper elevation={1} sx={{ p: 2, mb: 4 }}>
            <Typography variant="h6" gutterBottom>
                Your Event Details
              </Typography>
              <Divider sx={{ my: 2 }} />

              {/* Event Vision Section */}
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h7" sx={{ fontWeight: 'bold'}}>Event Vision</Typography>
                {isEditingVision ? (
                  <Box display="flex" alignItems="center">
                    <TextField
                      multiline
                      value={newEventVision}
                      onChange={(e) => setNewEventVision(e.target.value)}
                      size="small"
                      style={{ width: '100%', marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }}
                    />
                    <Button variant="text" onClick={handleSaveVisionClick}>Save</Button>
                    <Button variant="text" onClick={handleCancelVisionClick}>Cancel</Button>
                  </Box>
                ) : (
                  <Typography variant="body1" sx={{ marginLeft: '10px', flexGrow: 1 }}>{eventVision}</Typography>
                )}
                {!isEditingVision && <Button variant="text" onClick={handleEditVisionClick}>Edit</Button>}
              </Box>

              {/*Target Audience  */}
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body1" sx={{ fontWeight: 'bold'}}>Target Audience</Typography>
                {isEditingAudience ? (
                  <Box display="flex" alignItems="center">
                    <TextField
                      multiline
                      value={newTargetAudience}
                      onChange={(e) => setNewTargetAudience(e.target.value)}
                      size="small"
                      style={{ width: '100%', marginRight: '10px' }}
                    />
                    <Button variant="text" onClick={handleSaveAudienceClick}>Save</Button>
                    <Button variant="text" onClick={handleCancelAudienceClick}>Cancel</Button>
                  </Box>
                ) : (
                  <Typography variant="body1">{targetAudience}</Typography>
                )}
                {!isEditingAudience && <Button variant="text" onClick={handleEditAudienceClick}>Edit</Button>}
              </Box>
              
            </Paper>
          </Grid>

          <Grid item xs={12} md={5}>
            <Paper elevation={1} sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Venue and Add-ons
              </Typography>
              <Divider sx={{ my: 2 }} />
              {venue && (
                <Box display="flex" alignItems="center" mb={2}>
                  <img
                    src={venue.VenueImages && venue.VenueImages[0].url}
                    alt={venue.title}
                    style={{ borderRadius: '8px', marginRight: '16px', width: '150px', height: '100px', objectFit: 'cover' }}
                  />
                  <Box>
                    <Typography variant="h6">{venue.title}</Typography>
                    {/* <Typography variant="body2">{venue.desc}</Typography> */}
                    <Box display="flex" justifyContent="space-between" mb={1}>
                      <Typography variant="body2">Price</Typography>
                      <Typography variant="body2">${venue.startPrice}</Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              <Divider sx={{ my: 2 }} />
              {cartItems.map((item, index) => (
                <Box key={index} mb={2}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box>
                      <Typography variant="body1">{item.companyName}</Typography>
                      <Typography variant="body2">{item.addonType}</Typography>
                      {/* <Typography variant="body2">{item.desc}</Typography> */}
                    </Box>
                    <IconButton onClick={() => handleRemoveAddon(item.addonId)}>
                      <RemoveIcon />
                    </IconButton>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mb={1}>
                    <Typography variant="body2">Price</Typography>
                    <Typography variant="body2">
                    {item.price === null ? (
                          <span style={{ color: 'green' }}>PENDING</span>
                        ) : (
                          `$ ${item.price}`
                        )}
                        </Typography>
                  </Box>
                  {index < cartItems.length - 1 && <Divider sx={{ my: 2 }} />}
                </Box>
              ))}

              <Divider sx={{ my: 2 }} />

              <Box display="flex" justifyContent="space-between">
                <Typography variant="body1" fontWeight="bold">Total (USD)</Typography>
                <Typography variant="body1" fontWeight="bold">${calculateTotal()}</Typography>
              </Box>
            </Paper>
            <Box mt={4}>
              <Button variant="contained" color="primary" size="large" onClick={handleCheckoutButtonClick}>
                Checkout ${calculateTotal()} now
              </Button>
            </Box>
          </Grid>
        </Grid>

        <div className="row">
          <Divider style={{ margin: '20px 0' }} />
          <CollaboratorModal
            open={modalOpen}
            onClose={handleCloseModal}
            content={modalContent}
            onAddAddon={() => { handleAddAddonFromModal(); }}
          />
        <Box mt={6}>
          <Typography variant="h6">Recommended Add-ons to Complete your Event</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <div className={classes.sponsors}>Catering</div>
                <CardRow cards={recommendedAddons} onCardClick={handleOpenModal} onQuickAdd={handleQuickAdd} />
              </Grid>
              <Grid item xs={12} md={12}>
                <div className={classes.sponsors}>Sponsors</div>
                <CardRow cards={recommendedSponsors}  onCardClick={handleOpenModal} onQuickAdd={handleQuickAdd} />
              </Grid>
            </Grid>
          </Box>
          </div>

      </Box>
      <ToastContainer />
    </div>
  );
};

export default ShoppingCartPage;