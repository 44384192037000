import React from 'react';
import { Box, Card, CardContent, CardMedia, Typography, Button } from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const EventCard = ({ event, onClick }) => {
  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }} onClick={onClick}>
        <CardMedia
            component="img"
            height="140"
            image={event.imageUrl}
            alt={event.eventName}
        />
        <CardContent>
            <Typography variant="h6" component="h3" gutterBottom>
            {event.eventName}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <PushPinIcon sx={{ mr: 1, fontSize: 'small', color: 'primary.main'}} />
            <Typography variant="body2" color="text.secondary">
                {event.venueName}
            </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <PersonIcon sx={{ mr: 1, fontSize: 'small', color: 'primary.main' }} />
            <Typography variant="body2" color="text.secondary">
                {event.guestCount || 'Guest count not available'} guests
            </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CalendarTodayIcon sx={{ mr: 1, fontSize: 'small', color: 'primary.main' }} />
            <Typography variant="body2" color="text.secondary">
                {event.date}
            </Typography>
            </Box>
        </CardContent>
    </Card>
  );
};

export default EventCard;