import React, { useState, useEffect } from 'react';
import { Box, TextField, IconButton, Paper, Chip, Typography, Autocomplete, Link } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import TagIcon from '@mui/icons-material/LocalOffer';

// Predefined list of taggable locations
const tagOptions = [
  { label: 'Target Audience', link: 'internal:Event-Basics-section' },
  { label: 'Audience Information', link: 'internal:Event-Basics-section' },
  { label: 'Marketing and Promotion', link: 'internal:Event-Basics-section' },
  { label: 'Venue', link: 'internal:Event-Basics-section' },
  { label: 'Date and Time', link: 'internal:Event-Basics-section' },
  { label: 'Speakers', link: 'internal:Event-Basics-section' },
  { label: 'Agenda', link: 'internal:Event-Timeline-section' },
  { label: 'Sponsors', link: 'internal:Event-Basics-section' },
  { label: 'Marketing', link: 'internal:Event-Basics-section' },
  { label: 'Budget', link: 'internal:Event-Basics-section' },
  { label: 'Catering', link: 'internal:Event-Basics-section' },
];

function MessageInput({ onSendMessage }) {
  const [message, setMessage] = useState('');
  const [tags, setTags] = useState([]);
  const [suggestedTags, setSuggestedTags] = useState([]);

  useEffect(() => {
    // Analyze message content and suggest tags
    const words = message.toLowerCase().split(' ');
    const suggested = tagOptions.filter(tag => 
      words.some(word => tag.label.toLowerCase().includes(word))
    );
    setSuggestedTags(suggested);
  }, [message]);

  const handleSend = () => {
    if (message.trim() || tags.length > 0) {
      onSendMessage({ message: message.trim(), tags });
      setMessage('');
      setTags([]);
    }
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
    // Implement inline tagging
    const lastWord = event.target.value.split(' ').pop();
    if (lastWord.startsWith('#')) {
      // Show autocomplete for tags
    }
  };

  const handleTagAdd = (tag) => {
    if (!tags.find(t => t.label === tag.label)) {
      setTags([...tags, tag]);
    }
  };

  const handleTagDelete = (tagToDelete) => {
    setTags(tags.filter((tag) => tag.label !== tagToDelete.label));
  };

  return (
    <Paper elevation={1} sx={{ p: 1, borderRadius: 0, borderTop: '1px solid #e0e0e0' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            fullWidth
            placeholder="Type your message..."
            variant="outlined"
            size="small"
            value={message}
            onChange={handleMessageChange}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSend();
              }
            }}
          />
          <IconButton color="primary" onClick={handleSend}>
            <SendIcon />
          </IconButton>
        </Box>
        <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          {tags.map((tag) => (
            <Chip
              key={tag.label}
              label={tag.label}
              onDelete={() => handleTagDelete(tag)}
              size="small"
              sx={{ mr: 0.5, mb: 0.5 }}
              component={Link}
              href={tag.link}
              clickable
            />
          ))}
        </Box>
        <Box sx={{ mt: 1 }}>
          <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center' }}>
            <TagIcon fontSize="small" sx={{ mr: 0.5 }} />
            Add Reference Tags:
          </Typography>
          <Autocomplete
            multiple
            options={suggestedTags}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  label={option.label}
                  {...getTagProps({ index })}
                  component={Link}
                  href={option.link}
                  clickable
                />
              ))
            }
            value={tags}
            onChange={(event, newValue) => {
              setTags(newValue);
            }}
            sx={{ mt: 0.5 }}
          />
        </Box>
      </Box>
    </Paper>
  );
}

export default MessageInput;
