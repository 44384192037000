import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Divider, Link, Box, Container, Grid, Typography, Card, CardContent, CardMedia, Button, Paper, TextField } from '@mui/material';
import MyEventsAnalytics from './MyEventAnalytics';
import EventTimeline from './EventTimeline';
import DocumentUploader from './DocumentUploader';
import EventDetails from './EventDetails';
import TasksTable from './TasksTable';
import ChatBotIcon from './ChatIcon'; // Import the new component
import MessagesPage from 'pages/MessagesPage';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PeopleIcon from '@mui/icons-material/People';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';


const eventDetails1 = {
  basics: {
    name: 'X Ventures Networking and Panel',
    date: 'August 21, 2024',
    time: '17:30 - 21:00',
    duration: '3.5 hours',
    vision: 'Connecting top AI innovators with industry leaders during NYC Tech Week',
    type: 'Networking and Panel Discussion'
  },
  audience: {
    target: 'Founders, VCs, Software Engineers, AI Enthusiasts',
    expectedAttendees: 150,
    demographics: {
      ageRange: '25-45',
      background: 'Tech professionals, Entrepreneurs, Investors'
    }
  },
  venue: {
    name: 'Lume Studios, NYC',
    location: 'Lume Studios, NYC',
    capacity: 500,
    description: 'LUME Studios is an immersive venue and experiential creative studio. Through the use of high end visual software, projection mapping and spatial audio we create mind-blowing environments that activate unforgettable experiences for our clients. ',
    imageUrl: 'https://bvhldkvbwncfqndxjncl.supabase.co/storage/v1/object/public/venue-images-store/venues/db85687b-8049-40e4-a6f1-0ab3715afbf5/82f89f6d-b20a-47fd-8e9c-436558be8e80_LumeStudiosX.jpg',
    link: '/venue/db85687b-8049-40e4-a6f1-0ab3715afbf5',
  },
  description: 'X Ventures Networking and Panel is a premier event showcasing top class AI companies in the current cohert as well as hosting a panel of some of the brightest minds in AI.',
  cost: 20000,
  images: [
    'https://your-image-url.com/conference1.jpg',
    'https://your-image-url.com/conference2.jpg',
    'https://your-image-url.com/conference3.jpg',
  ],
  venueFeatures: {
    seating: ''
  },
  addons: [
    { category: 'Venue', name: 'VIP Seating', price: 500, confirmed: true },
    { category: 'Catering', name: 'Tastings NYC | SoFlo Catering', price: 5000, confirmed: false },
    { category: 'Photography', name: 'Premium Pictures', price: 1000, confirmed: true }, 
    { category: 'Marketing', name: 'Instagram Ads', price: 1000, confirmed: true },
    { category: 'Marketing', name: 'NYC Tech Influencers', price: 2000, confirmed: true }
  ],
  sponsors: [
    { name: 'TechCorp', logoUrl: 'https://your-image-url.com/techcorp-logo.jpg', price: 2000 },
    { name: 'InnovateX', logoUrl: 'https://your-image-url.com/innovatex-logo.jpg', price: 2000 },
  ],
  format: {
    agenda: '17:30 - Networking, 18:30 - Panel Discussion, 20:00 - Happy Hour',
    keyActivities: ['Keynote Speech', 'Panel Discussion', 'Q&A Session', 'Networking'],
    specialFeatures: ['VIP Lounge', 'AI Demo Area']
  },
  speakers: [
    { name: 'Jane Doe', bio: 'CEO of AI Innovations Inc.' },
    { name: 'John Smith', bio: 'Renowned AI Researcher' },
    { name: 'Emily Chen', bio: 'Partner at Tech Ventures Capital' }
  ],
  marketing: {
    channels: ['LinkedIn', 'Twitter', 'Email Newsletter', 'Tech Blogs'],
    strategy: 'Leverage NYC Tech Week buzz, partner with tech influencers',
    expectedMediaCoverage: 'TechCrunch, Venture Beat, local NYC tech publications'
  },
  financials: {
    venueCost: 20000,
    addons: [
      { category: 'Venue', name: 'VIP Seating', price: 500, confirmed: true },
      { category: 'Catering', name: 'Tastings NYC | SoFlo Catering', price: 5000, confirmed: false },
      { category: 'Photography', name: 'Premium Pictures', price: 1000, confirmed: true },
      { category: 'Marketing', name: 'Instagram Ads', price: 1000, confirmed: true },
      { category: 'Marketing', name: 'NYC Tech Influencers', price: 2000, confirmed: true }
    ]
  }
};

const EventDetailsPage = () => {
  const [currentTab, setCurrentTab] = useState('overview');
  const [eventDetails, setEventData] = useState(eventDetails1);
  const [isAdditionalCardOpen, setAdditionalCardOpen] = useState(false);
  const [editableFields, setEditableFields] = useState({
    vision: 'A networking event connecting top industry leaders.',
    audience: 'Founders, VCs, Software Engineers',
    type: 'Corporate Networking, Panel Discussion',
  });

  // Extract addons from eventDetails1
  const [addons, setAddons] = useState(
    eventDetails1.addons.map((addon, index) => ({
      id: index + 1,
      name: addon.name,
      category: addon.category,
      price: addon.price,
      confirmed: addon.confirmed
    }))
  );

  // Create timeline events based on eventDetails1
  const [timelineEvents, setTimelineEvents] = useState([
    { id: 1, time: eventDetails1.basics.time.split(' - ')[0], title: 'Event Start', description: 'Guests begin to arrive', parties: ['Venue', 'Catering'] },
    { id: 2, time: '17:30', title: 'Networking', description: 'Drinks and small plates served', parties: ['Catering'] },
    { id: 3, time: '18:30', title: 'Panel Discussion', description: 'Panel of speakers begins', parties: ['Speakers'] },
    { id: 4, time: '20:00', title: 'Happy Hour', description: 'Networking continues with drinks', parties: ['Catering'] },
    { id: 5, time: eventDetails1.basics.time.split(' - ')[1], title: 'Event End', description: 'Event concludes', parties: ['All'] },
    { id: 6, time: '21:30', title: 'Cleanup', description: 'Venue cleanup begins', parties: ['Venue', 'Catering'] },
  ]);

  const [messageTags, setMessageTags] = useState([]);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleAddTag = (tag) => {
    setMessageTags((prevTags) => [...prevTags, tag]);
  };

  const renderTabContent = () => {
    switch (currentTab) {
      case 'overview':
        return (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <EventDetails 
                eventData={eventDetails} 
                setEventData={setEventData} 
                onAddTag={handleAddTag}
              />
            </Grid>
          </Grid>
        );
      case 'agenda':
        return (
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 3 }} id="Event-Timeline-section">
              <Typography variant="h5" component="h2" gutterBottom>
                Event Timeline
              </Typography>
              <EventTimeline 
                events={timelineEvents} 
                onAddEvent={handleAddTimelineEvent}
                onEditEvent={handleEditTimelineEvent}
                onDeleteEvent={handleDeleteTimelineEvent}
                onSyncGoogleCalendar={handleGoogleCalendarSync}
                addons={addons}
              />
            </Paper>
          </Grid>
        );
      case 'notifications':
        return (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <MessagesPage messageTags={messageTags} setMessageTags={setMessageTags} />
            </Grid>
          </Grid>
        );
      case 'tasks':
        return (
          <Grid item xs={12}>
            <TasksTable eventDetails={eventDetails}/>
        </Grid>
        );
      case 'eventAnalytics':
        return (
          <Grid item xs={12}>
            <MyEventsAnalytics eventDetails={eventDetails} />
          </Grid>
        );
      }
    };

  const handleAddTimelineEvent = (newEvent) => {
    const newId = Math.max(...timelineEvents.map(e => e.id)) + 1;
    setTimelineEvents([...timelineEvents, { ...newEvent, id: newId }]);
  };

  const handleEditTimelineEvent = (editedEvent) => {
    const updatedEvents = timelineEvents.map(event => 
      event.id === editedEvent.id ? editedEvent : event
    );
    setTimelineEvents(updatedEvents);
  };

  const handleDeleteTimelineEvent = (eventToDelete) => {
    const updatedEvents = timelineEvents.filter(event => event.id !== eventToDelete.id);
    setTimelineEvents(updatedEvents);
  };

  const handleGoogleCalendarSync = async () => {
    try {
      // This is a placeholder for the actual Google Calendar API integration
      // You'll need to implement the actual API calls here
      console.log('Syncing with Google Calendar...');
      
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      console.log('Sync completed');
      // You might want to show a success message to the user here
    } catch (error) {
      console.error('Error syncing with Google Calendar:', error);
      // You might want to show an error message to the user here
    }
  };

  const handleOpenAdditionalCard = () => {
    setAdditionalCardOpen(true);
  };

  const handleCloseAdditionalCard = () => {
    setAdditionalCardOpen(false);
  };

  const handleChange = (field) => (event) => {
    setEditableFields({
      ...editableFields,
      [field]: event.target.value,
    });
  };

  const totalCost = (eventDetails.cost 
    + eventDetails.addons.reduce((sum, addon) => sum + addon.price, 0)
    - eventDetails.sponsors.reduce((sum, sponsor) => sum + sponsor.price, 0)).toLocaleString();

  const handleDocumentUpload = (parsedData) => {
    setEditableFields(prevFields => ({
      ...prevFields,
      ...parsedData
    }));
    // Update other components or state here as well
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      // Determine which tab contains this section
      let tabValue = 'overview'; // default
      if (sectionId === 'Event-Timeline-section') {
        tabValue = 'agenda';
      }
      // Add more conditions for other sections if needed

      // Change to the correct tab
      setCurrentTab(tabValue);

      // Use setTimeout to allow the tab content to render before scrolling
      setTimeout(() => {
        section.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  };

  useEffect(() => {
    // Make the scrollToSection function globally accessible
    window.scrollToSection = scrollToSection;

    // Clean up
    return () => {
      delete window.scrollToSection;
    };
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {/* Event Title */}
      <Typography variant="h4" component="h1" gutterBottom>
        {eventDetails.basics.name}
      </Typography>

      {/* Event Details in Styled Boxes */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Paper elevation={1} sx={{ p: 1, borderRadius: 2, display: 'flex', alignItems: 'center' }}>
            <CalendarMonthIcon sx={{ mr: 1 }} />
            {/* <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Date</Typography> */}
            <Typography variant="body2">{eventDetails.basics.date}</Typography>
          </Paper>
          <Paper elevation={1} sx={{ p: 1, borderRadius: 2, display: 'flex', alignItems: 'center' }}>
            <LocationOnIcon sx={{ mr: 1 }} />
            <Typography variant="body2">{eventDetails.venue.location}</Typography>
          </Paper>
          <Paper elevation={1} sx={{ p: 1, borderRadius: 2,display: 'flex', alignItems: 'center' }}>
            <PeopleIcon sx={{ mr: 1 }} />
            <Typography variant="body2">{eventDetails.audience.expectedAttendees}</Typography>
          </Paper>
          <Paper elevation={1} sx={{ p: 1, borderRadius: 2, display: 'flex', alignItems: 'center'}}>
            <AccessTimeIcon sx={{ mr: 1 }} />
            {/* <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Time</Typography> */}
            <Typography variant="body2">{eventDetails.basics.time}</Typography>
          </Paper>
        </Box>

        {/* Document Uploader Button on the Right */}
        <DocumentUploader onUploadComplete={() => {}} />
      </Box>

    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
      <Tabs value={currentTab} onChange={handleTabChange} aria-label="event details tabs" sx={{ mb: 4 }}>
        <Tab label="Overview" value="overview" />
        <Tab label="Agenda" value="agenda" />
        <Tab label="Notifications" value="notifications" />
        <Tab label="Vendors" value="vendors" />
        <Tab label="Tasks" value="tasks" />
        <Tab label="Sponsor Deck" value="sponsorDeck" />
        <Tab label="Event Analytics" value="eventAnalytics" />
      </Tabs>
      </Box>

      {renderTabContent()}

      {/* Add the ChatBotIcon component */}
      <ChatBotIcon />
    </Container>
  );
};

export default EventDetailsPage;