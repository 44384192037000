import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import VenueBox from '../components/VenueBox';
import FilterBar from 'components/FilterBar/FilterBar';
import SortDropdown from 'components/sortDropDown';
import DateFilter from 'components/DateFilter';
import FilterListIcon from '@mui/icons-material/FilterList'; // Import the filter icon

const MarketplacePage = () => {
    const [venues, setVenues] = useState([]);
    const [error, setError] = useState(false);
    const [sort, setSort] = useState('newest');
    const [filter, setFilter] = useState('All');
    const [priceRange, setPriceRange] = useState([0, 1000000]); // Initial range values
    const [open, setOpen] = useState(false);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(1000);

    useEffect(() => {
        const fetchVenues = async () => {
            try {
                const response = await axios.post('/supabase/venues');
                if (Array.isArray(response.data.data)) {
                    const sortedVenues = sortVenues(response.data.data, sort);
                    setVenues(sortedVenues);
                } else {
                    throw new Error('Response is not an array');
                }
            } catch (err) {
                setError(err.message);
            }
        };

        fetchVenues();
    }, []);

    useEffect(() => {
        setVenues(prevVenues => sortVenues([...prevVenues], sort));
    }, [sort]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleApplyPriceFilter = () => {
        setPriceRange([minPrice, maxPrice]);
        handleClose();
    };

    const filteredVenues = venues.filter(venue => 
        (filter === 'All' || (venue.eventType && venue.eventType.includes(filter))) &&
        venue.startPrice >= priceRange[0] &&
        venue.startPrice <= priceRange[1]
    );

    const sortedVenues = filteredVenues.sort((a, b) => {
        if (sort === 'newest') {
            return new Date(b.created) - new Date(a.created);
        } else if (sort === 'oldest') {
            return new Date(a.created) - new Date(b.created);
        } else if (sort === 'lowerPrice') {
            return a.startPrice - b.startPrice;
        } else if (sort === 'higherPrice') {
            return b.startPrice - a.startPrice;
        } else {
            return 0;
        }
    });

    const sortVenues = (venues, sort) => {
        switch (sort) {
            case 'oldest':
                return venues.sort((a, b) => new Date(a.created) - new Date(b.created));
            case 'newest':
                return venues.sort((a, b) => new Date(b.created) - new Date(a.created));
            case 'lowerPrice':
                return venues.sort((a, b) => a.startPrice - b.startPrice);
            case 'higherPrice':
                return venues.sort((a, b) => b.startPrice - a.startPrice);
            default:
                return venues;
        }
    };

    return (
        <div className="container mt-4">
            <div>
                <FilterBar setFilter={setFilter} />
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '20px', justifyContent: 'flex-end' }}>
                    <SortDropdown setSort={setSort} />
                    <DateFilter />
                    <Button
                        variant="outlined"
                        startIcon={<FilterListIcon />}
                        style={{ marginLeft: '10px' }}
                        onClick={handleOpen}
                    >
                        Filter
                    </Button>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                    <h1>Venues</h1>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                        {sortedVenues.map(venue => (
                            <VenueBox key={venue.venueId} venue={venue} />
                        ))}
                    </div>
                </div>

                {/* Modal for price filter */}
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Filter by Price</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Minimum Price"
                            type="number"
                            fullWidth
                            variant="standard"
                            value={minPrice}
                            onChange={(e) => setMinPrice(parseFloat(e.target.value))}
                        />
                        <TextField
                            margin="dense"
                            label="Maximum Price"
                            type="number"
                            fullWidth
                            variant="standard"
                            value={maxPrice}
                            onChange={(e) => setMaxPrice(parseFloat(e.target.value))}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleApplyPriceFilter}>Next</Button>
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
};

export default MarketplacePage;
