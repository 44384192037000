import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { Box } from '@mui/material';

const clientId = process.env.REACT_APP_GOOGLE_CLIENTID_LOGIN;

async function addUser(userData) {
    try {
        const apiUrl = '/supabase/user/user';

        const payload = {
            userId: userData.sub,
            createdTs: userData.iat,
            firstName: userData.given_name,
            lastName: userData.family_name,
            email: userData.email,
            type: "Host"
        };

        const response = await axios.post(apiUrl, payload);

        if (response.status === 200) {
            console.log(response);
            console.log(payload);
            console.log("Successfully added user to database.");
        } else {
            const errors = { addUser: response.statusText };
            console.error('Error adding user: ', response.statusText);
            Object.values(errors).forEach((error) => {
                toast.error(error);
            });
        }
    } catch (error) {
        console.error('Error adding new user to database:', error);
        toast.error('An error occurred while adding the user.');
    }
}

const GoogleSignIn = ({ onUserLogin }) => {

    const onSuccess = async (credentialResponse) => {
        try {
            const userData = jwtDecode(credentialResponse.credential);
            console.log("userData pre");
            console.log(userData);
            console.log(localStorage);
            await addUser(userData);

            // Save token to local storage
            localStorage.setItem('authToken', credentialResponse.credential);

            localStorage.setItem('userData', JSON.stringify(userData));
            // setUser(userData); 
            // console.log("localStorage google sign in");
            console.log("localStorage, after");
            console.log(localStorage);
            onUserLogin(userData);
        } catch (error) {
            console.error('Error during Google Sign-In:', error);
            toast.error('Failed to log in.');
        }
    };

    const onFailure = (error) => {
        console.log('Login Failed:', error);
        toast.error('Login failed.');
    };

    // useEffect(() => {
    //     // Load user info from local storage on component mount
    //     const token = localStorage.getItem('authToken');
    //     if (token) {
    //         try {
    //             const userData = jwtDecode(token);
    //             setUser(userData);
    //         } catch (error) {
    //             console.error('Error decoding token:', error);
    //             localStorage.removeItem('authToken'); // Clear invalid token
    //         }
    //     }
    // }, []);

    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <GoogleOAuthProvider clientId={clientId}>
                <Box sx={{ width: '100%' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <GoogleLogin
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            style={{ width: '100%' }}
                        />
                    </div>
                </Box>
            </GoogleOAuthProvider>
            <ToastContainer />
        </Box>
    );
};

export default GoogleSignIn;
