import React, { useState } from 'react';
import { Box, Typography, Paper, LinearProgress, Grid, TextField, Button, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

const BudgetChart = () => {
  // State for managing edit mode and budget value
  const [isEditing, setIsEditing] = useState(false);
  const [budget, setBudget] = useState(100000); // Default budget value
  const [inputValue, setInputValue] = useState(budget);

  // Handle save action
  const handleSave = () => {
    setBudget(inputValue);
    setIsEditing(false);
  };

  // Handle cancel action
  const handleCancel = () => {
    setInputValue(budget);
    setIsEditing(false);
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Annual Budget for 2024</Typography>
            {!isEditing && (
              <IconButton onClick={() => setIsEditing(true)}>
                <EditIcon />
              </IconButton>
            )}
          </Box>

          {!isEditing ? (
            // Display mode: Show progress bar and budget details
            <Box mt={2}>
              <LinearProgress variant="determinate" value={(budget / 100000) * 100} />
              <Box display="flex" justifyContent="space-between" mt={1}>
                <Typography variant="body2">${0}</Typography>
                <Typography variant="body2">${budget} total</Typography>
              </Box>
              <Typography variant="caption" color="textSecondary">
                Spend from past events
              </Typography>
            </Box>
          ) : (
            // Edit mode: Show input field for budget and action buttons
            <Box mt={2} display="flex" alignItems="center">
              <TextField
                type="number"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                variant="outlined"
                size="small"
                sx={{ mr: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                startIcon={<SaveIcon />}
                sx={{ mr: 1 }}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
                startIcon={<CancelIcon />}
              >
                Cancel
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}


export default BudgetChart;