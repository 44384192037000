import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, Card, CardContent, Box, Stepper, Step, StepLabel, TextField, Button, IconButton, Tooltip, FormControlLabel, Checkbox } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InfoIcon from '@mui/icons-material/Info';

const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6', 'Step 7'];

const eventCategories = {
  Professional: ['Networking event', 'Seminar/Panel', 'Conference'],
  Social: ['Happy Hour', 'Dinner', 'Party'],
  Interactive: ['Hackathon', 'Team Building', 'Other'],
};

const EventRentStartPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    eventType: [],
    location: '',
    guests: '',
    budget: '',
    additionalInfo: '',
  });
  const [showForm, setShowForm] = useState(false);
  const history = useHistory();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (event, category, type) => {
    const newEventType = formData.eventType.includes(type)
      ? formData.eventType.filter((t) => t !== type)
      : [...formData.eventType, type];
    setFormData({ ...formData, eventType: newEventType });
  };

  const handleSkip = () => {
    handleNext();
  };

  const handleMagicHappen = () => {
    setShowForm(true);
  };

  const handleDoItMyself = () => {
    history.push(`/marketplace`);
  };

  const handleBackToOptions = () => {
    setShowForm(false);
  };

  return (
    <>
      <Grid container sx={{ height: 'calc(100vh - 64px)' }}>
        {/* Left Side Content */}
        <Grid item xs={8} md={5} sx={{ backgroundColor: '#1976d2', color: '#fff', paddingLeft: 10, paddingRight: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="h4" gutterBottom sx={{ mt: 2 }}>
            Effortlessly Plan, Manage, and Elevate Your Events
          </Typography>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Card sx={{ backgroundColor: 'transparent', color: '#fff', boxShadow: 'none' }}>
              <CardContent>
                <img
                  src="https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/EventRent_%20copy.png?v=2024-05-20T18:28:05.840Z"
                  alt="EventRent"
                  style={{ maxWidth: '100%', maxHeight: '500px', alignItems: 'center', transform: 'translateY(10px)', transition: 'transform 0.5s ease-in-out' }}
                  onMouseOver={(e) => (e.currentTarget.style.transform = 'translateY(0px)')}
                  onMouseOut={(e) => (e.currentTarget.style.transform = 'translateY(10px)')}
                />
              </CardContent>
            </Card>
          </Box>
        </Grid>

        {/* Right Side Content/Form */}
        <Grid item xs={12} md={7} sx={{ padding: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          {!showForm ? (
            <Box textAlign="center">
              <Typography variant="h4" gutterBottom>
                Let's plan your event 🚀
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 4 }}>
                We promise it'll only take a few moments to create. Choose an option below.
              </Typography>
              <Grid container spacing={4} justifyContent="center">
                <Grid item>
                  <Button
                    variant="outlined"
                    sx={{ padding: 2, width: 250, height: 150, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                    onClick={handleDoItMyself}
                  >
                    <Typography variant="h6" sx={{ marginBottom: 1, textAlign: 'center' }}>Create From Scratch</Typography>
                    <Typography variant="body2" sx={{ textAlign: 'center' }}>
                      Create your event from scratch, giving you full control over every detail.
                    </Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    sx={{ padding: 2, width: 250, height: 150, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                    onClick={handleMagicHappen}
                  >
                    <Typography variant="h6" sx={{ marginBottom: 1, textAlign: 'center' }}>Plan with AI</Typography>
                    <Typography variant="body2" sx={{ textAlign: 'center' }}>
                      Answer a few questions and let our AI assistant generate an event template for you!
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 3 }}>
                <Typography variant="body2">{`${activeStep + 1} / ${steps.length}`}</Typography>
                <Stepper activeStep={activeStep} alternativeLabel sx={{ flexGrow: 1, marginLeft: 2, marginRight: 2 }}>
                  {steps.map((_, index) => (
                    <Step key={index} sx={{ padding: 0 }}>
                      <StepLabel
                        StepIconComponent={() => (
                          <Box
                            sx={{
                              width: 8,
                              height: 8,
                              backgroundColor: activeStep >= index ? '#1976d2' : '#e0e0e0',
                              borderRadius: '50%',
                            }}
                          />
                        )}
                      />
                    </Step>
                  ))}
                </Stepper>
                <Button variant="text" onClick={handleBackToOptions}>
                  Back to Options
                </Button>
              </Box>
              
              {/* Conditional rendering based on the active step */}
              <Box sx={{ marginY: 4 }}>
                {activeStep === 0 && (
                  <>
                    <Typography variant="h6" sx={{ marginBottom: 2 }}>
                      First thing — what's your first name?
                    </Typography>
                    <TextField
                      fullWidth
                      name="name"
                      label="ex: Afra Nehal"
                      variant="outlined"
                      value={formData.name}
                      onChange={handleChange}
                      sx={{ marginBottom: 3 }}
                    />
                  </>
                )}
                {activeStep === 1 && (
                  <Box>
                    <Typography variant="h6" sx={{ marginBottom: 2 }}>
                      What type of event are you looking to host?
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      {Object.entries(eventCategories).map(([category, types]) => (
                        <Box key={category} sx={{ width: '30%' }}>
                          <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                            {category}
                          </Typography>
                          {types.map((type) => (
                            <FormControlLabel
                              key={type}
                              control={
                                <Checkbox
                                  checked={formData.eventType.includes(type)}
                                  onChange={(e) => handleCheckboxChange(e, category, type)}
                                  name={type}
                                />
                              }
                              label={type}
                              sx={{ display: 'block', marginBottom: 1 }}
                            />
                          ))}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
                {activeStep === 2 && (
                  <Box>
                    <Typography variant="h6" sx={{ marginBottom: 2 }}>
                      Where do you want to host your {formData.eventType.length === 1 ? formData.eventType[0] : 'event'}?
                    </Typography>
                    <TextField
                      fullWidth
                      name="location"
                      label="Location"
                      variant="outlined"
                      value={formData.location}
                      onChange={handleChange}
                      sx={{ marginBottom: 3 }}
                    />
                  </Box>
                )}
                {activeStep === 3 && (
                  <Box>
                    <Typography variant="h6" sx={{ marginBottom: 2 }}>
                      How many guests are you expecting?
                    </Typography>
                    <TextField
                      fullWidth
                      name="guests"
                      label="Number of guests"
                      variant="outlined"
                      value={formData.guests}
                      onChange={handleChange}
                      sx={{ marginBottom: 3 }}
                    />
                  </Box>
                )}
                {activeStep === 4 && (
                  <Box>
                    <Typography variant="h6" sx={{ marginBottom: 2 }}>
                      How much do you want to spend?
                    </Typography>
                    <TextField
                      fullWidth
                      name="budget"
                      label="Budget"
                      variant="outlined"
                      value={formData.budget}
                      onChange={handleChange}
                      sx={{ marginBottom: 3 }}
                    />
                  </Box>
                )}
                {activeStep === 5 && (
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                      <Typography variant="h6" sx={{ marginRight: 1 }}>
                        Almost there! Is there anything else we should know?
                      </Typography>
                      <Tooltip title="This information will help our AI assistant understand your event. So please let us know in however much detail how you imagine this event.">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      name="additionalInfo"
                      label="Additional Information"
                      variant="outlined"
                      value={formData.additionalInfo}
                      onChange={handleChange}
                      sx={{ marginBottom: 3 }}
                    />
                  </Box>
                )}
                {activeStep === 6 && (
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="h6" sx={{ marginBottom: 2 }}>
                      Congratulations! You're all set to generate your event.
                    </Typography>
                    <Button variant="contained" color="primary" onClick={() => alert('Event Generated')}>
                      Generate my event
                    </Button>
                  </Box>
                )}
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button
                  startIcon={<ArrowBackIcon />}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Back
                </Button>
                <Button
                  variant="text"
                  onClick={handleSkip}
                >
                  Skip for now
                </Button>
                <Button
                  endIcon={<ArrowForwardIcon />}
                  variant="contained"
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Continue'}
                </Button>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default EventRentStartPage;
