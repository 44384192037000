import React from 'react';
import { Avatar, Tooltip } from '@mui/material';
import { styled } from '@mui/system';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  fontSize: '1rem',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const ProfileIcon = ({ user, size = 'medium' }) => {
  const getInitials = (name) => {
    if (typeof name !== 'string') return '';
    return name
      .split(' ')
      .map((n) => n[0])
      .join('')
      .toUpperCase();
  };

  const sizeMap = {
    small: 30,
    medium: 40,
    large: 50,
  };

  const userName = typeof user === 'string' ? user : user.name || '';
  const userType = typeof user === 'string' ? user : user.type || 'User';

  return (
    <Tooltip title={userName} arrow>
      <StyledAvatar
        sx={{
          width: sizeMap[size],
          height: sizeMap[size],
          fontSize: size === 'small' ? '0.8rem' : size === 'large' ? '1.2rem' : '1rem',
          backgroundColor: 
            userType === 'Bot' ? 'secondary.main' : 
            userType === 'Self' ? 'primary.main' : 'success.main',
        }}
      >
        {getInitials(userName)}
      </StyledAvatar>
    </Tooltip>
  );
};

export default ProfileIcon;