import React, { useState } from 'react';
import { Box, Typography, TextField, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Chip, Tooltip, Card, CardContent, Menu, MenuItem } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
import { blue, green, orange, purple, red } from '@mui/material/colors';

const partyColors = {
  Catering: blue[500],
  Photography: green[500],
  Venue: orange[500],
  Cleaning: purple[500],
  Other: red[500],
};

const EventTimeline = ({ events, onAddEvent, onEditEvent, onDeleteEvent, addons, onSyncGoogleCalendar }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingEvent, setEditingEvent] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleOpenDialog = (event = null) => {
    setEditingEvent(event || { time: '', title: '', description: '', parties: [] });
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setEditingEvent(null);
  };

  const handleSaveEvent = () => {
    if (editingEvent.id) {
      onEditEvent(editingEvent);
    } else {
      onAddEvent(editingEvent);
    }
    handleCloseDialog();
  };

  const handleDeleteClick = (event) => {
    onDeleteEvent(event);
  };

  const handleAddPartyClick = (event, timelineEvent) => {
    setAnchorEl(event.currentTarget);
    setSelectedEvent(timelineEvent);
  };

  const handleAddPartyClose = () => {
    setAnchorEl(null);
    setSelectedEvent(null);
  };

  const handleAddParty = (party) => {
    if (selectedEvent) {
      const updatedEvent = {
        ...selectedEvent,
        parties: [...selectedEvent.parties, party]
      };
      onEditEvent(updatedEvent);
    }
    handleAddPartyClose();
  };

  // Group events by time
  const groupedEvents = events.reduce((acc, event) => {
    if (!acc[event.time]) {
      acc[event.time] = [];
    }
    acc[event.time].push(event);
    return acc;
  }, {});

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper', position: 'relative' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', mb: 2 }}>
        <Button
          variant="contained"
          startIcon={<SyncIcon />}
          onClick={onSyncGoogleCalendar}
          sx={{ 
            mb: 1,
            bgcolor: blue[700], 
            '&:hover': { bgcolor: blue[800] },
            color: 'white'
          }}
        >
          Sync with Google Calendar
        </Button>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
          sx={{ 
            bgcolor: blue[500], 
            '&:hover': { bgcolor: blue[700] },
            color: 'white'
          }}
        >
          Add Event
        </Button>
      </Box>
      <Timeline sx={{ mb: 4, pl: 0, '& .MuiTimelineItem-root::before': { flex: 0 } }}>
        {Object.entries(groupedEvents).map(([time, timeEvents], index) => (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot sx={{ bgcolor: blue[700] }} />
              <TimelineConnector sx={{ bgcolor: blue[700] }} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography variant="subtitle1" component="span" sx={{ color: blue[700], fontWeight: 'bold' }}>
                {time}
              </Typography>
              {timeEvents.map((event, eventIndex) => (
                <Card key={eventIndex} sx={{ mt: 2, mb: 2 }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                      <Box sx={{ flex: 1 }}>
                        <Typography variant="h6">{event.title}</Typography>
                        <Typography variant="body2" sx={{ mt: 0.5, mb: 1 }}>{event.description}</Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, alignItems: 'center' }}>
                          {event.parties.map((party, i) => (
                            <Chip key={i} label={party} size="small" sx={{ bgcolor: partyColors[party] || partyColors.Other, color: 'white' }} />
                          ))}
                          <Tooltip title="Add party">
                            <IconButton size="small" onClick={(e) => handleAddPartyClick(e, event)}>
                              <AddIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                      <Box>
                        <IconButton size="small" onClick={() => handleOpenDialog(event)}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton size="small" onClick={() => handleDeleteClick(event)}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleAddPartyClose}
      >
        {addons.map((addon) => (
          <MenuItem key={addon.id} onClick={() => handleAddParty(addon.name)}>
            {addon.name}
          </MenuItem>
        ))}
      </Menu>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>{editingEvent?.id ? 'Edit Event' : 'Add New Event'}</DialogTitle>
        <DialogContent>
          <TextField
            name="time"
            label="Time"
            value={editingEvent?.time || ''}
            onChange={(e) => setEditingEvent({ ...editingEvent, time: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            name="title"
            label="Title"
            value={editingEvent?.title || ''}
            onChange={(e) => setEditingEvent({ ...editingEvent, title: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            name="description"
            label="Description"
            value={editingEvent?.description || ''}
            onChange={(e) => setEditingEvent({ ...editingEvent, description: e.target.value })}
            fullWidth
            margin="normal"
            multiline
            rows={2}
          />
          <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>Parties Involved:</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {addons.map((addon) => (
              <Chip
                key={addon.id}
                label={addon.name}
                onClick={() => {
                  const parties = editingEvent.parties || [];
                  const updatedParties = parties.includes(addon.name)
                    ? parties.filter(p => p !== addon.name)
                    : [...parties, addon.name];
                  setEditingEvent({ ...editingEvent, parties: updatedParties });
                }}
                color={editingEvent?.parties?.includes(addon.name) ? "primary" : "default"}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSaveEvent} variant="contained" sx={{ bgcolor: blue[700], '&:hover': { bgcolor: blue[800] } }}>
            {editingEvent?.id ? 'Save' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EventTimeline;